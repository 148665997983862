/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import { Helmet } from "react-helmet"

import React from "react"
import CookieConsent, { Cookies } from "react-cookie-consent"

import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"

import Styles from "./index.module.scss"
import "./koreabase.css"

import { KoreaFooter } from "../footer"
import { KoreaHeader } from "../header"

type Props = {
  children: React.ReactNode
  language: 'en' | 'ko',
}

export const KoreaLayout: React.VFC<Props> = ({ children, language }) => {
  return (
    <>
      <Helmet>
        <body className="koreaBody" />
      </Helmet>
      <KoreaHeader language={language} />
      <main className={Styles.koreaMain} id="top">
        {children}
      </main>
      <KoreaFooter language={language} />
    </>
  )
}
