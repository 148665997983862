import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  MouseEvent,
} from "react"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Styles from "./index.module.scss"
import StylesMain from "../../pages/korea/index.module.scss"
import StylesContact from "../form/index.module.scss"
import { useLocation } from "@reach/router"

import TamLogo from "../../images/tam_logo.svg"

const dictionary = {
  en: {
    plan: "Plan",
    service: "Services",
    result: "Achievements",
    contact: "Contact",
  },
  ko: {
    plan: "일정안내",
    service: "서비스 메뉴",
    result: "지원 실적",
    contact: "문의사항",
  },
}

export const KoreaHeader: React.VFC<{ language: "en" | "ko" }> = ({
  language,
}) => {
  const [showNav, setShowNav] = useState(false)

  //koreaHeaderをuseRefで参照
  //useRefにHTMLElement型を明示的に指定
  const headerRef = useRef<HTMLElement | null>(null)

  //document変数が定義されているときのみ
  if (typeof document !== "undefined") {
    //直前のスクロール位置（スクロールが上か下どちらに入ったかを判断する基準）
    let set_position = 0

    window.addEventListener("scroll", () => {
      //現在のスクロール位置
      const currentPosition = document.documentElement.scrollTop
      // 画面幅が 767px 以下の場合、ヘッダーーの動作を無効化
      if (window.innerWidth <= 767) {
        return
      }
      //headerRef.currentが実際にDOM要素か確認
      if (headerRef.current instanceof HTMLElement) {
        const headerHeight = headerRef.current.offsetHeight

        if (currentPosition > headerHeight && set_position < currentPosition) {
          headerRef.current.classList.remove(Styles.isVisible)
          headerRef.current.classList.add(Styles.isHidden)
        } else if (
          currentPosition > headerHeight &&
          set_position > currentPosition
        ) {
          headerRef.current.classList.add(Styles.isVisible)
          headerRef.current.classList.remove(Styles.isHidden)
        } else {
          headerRef.current.classList.remove(Styles.isVisible)
          headerRef.current.classList.remove(Styles.isHidden)
        }
        set_position = currentPosition
      }
    })
  }

  //ページ内リンク
  const anchorScroll = (href: string) => {
    const targetEl = document.getElementById(href)
    if (targetEl) {
      const currentPosition = window.scrollY
      const targetTop = targetEl.getBoundingClientRect().top
      const headerElement = document.getElementById("header")
      const targetPosition = currentPosition + targetTop
      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      })
    }
  }

  //ハンバーガーメニューを開く前までいたスクロールポジションを管理
  const [scrollPosition, setScrollPosition] = useState(0)

  const toggleNavVisibility = (isOpen: boolean) => {
    setShowNav(isOpen)

    if (isOpen) {
      setScrollPosition(window.scrollY)
      document.body.classList.add("noScroll")
    } else {
      document.body.classList.remove("noScroll")
      window.scrollTo(0, scrollPosition)
    }
  }

  const toggleMenu = () => {
    toggleNavVisibility(!showNav)
  }

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>, href: string) => {
      e.preventDefault()
      // ウィンドウ幅が767px以下の場合のみtoggleNavVisibilityを実行
      if (window.innerWidth <= 767) {
        toggleNavVisibility(false)
      }
      // レンダリングでスクロールが上手くいかなったので0.3秒後スクロール開始
      setTimeout(() => {
        anchorScroll(href)
      }, 300)
    },
    [scrollPosition] // scrollPositionが変更されるたびにhandleClickを更新
  )

  const handleLanguageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newLanguage = event.target.value
    // 新しい言語に基づいてURLを切り替える
    const newPath = newLanguage === "en" ? "/korea_en/" : "/korea/"
    navigate(newPath)
  }

  return (
    <div className={Styles.koreaHeaderWrapper}>
      <header
        className={`${Styles.header} ${Styles.koreaHeader}`}
        id="header"
        ref={headerRef}
      >
        <div id="headerWrap" className={Styles.headerWrap}>
          <div className={Styles.headerInner}>
            <div className={Styles.headerLogo}>
              <a
                className={Styles.headerLogoCompany}
                href="https://www.tam-tam.co.jp/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={TamLogo}
                  alt="TAM Logo"
                  width="85"
                  height="50"
                  className={Styles.headerLogoImage}
                />
              </a>
            </div>
            <nav
              className={`${Styles.headerNav} ${
                showNav ? Styles.isVisible : ""
              }`}
              id="nav"
            >
              <ul className={Styles.navList}>
                {/* <li className={Styles.navItem}>
                  <a href="#" className={Styles.navLink}>
                    멤버 소개
                  </a>
                </li> */}
                <li className={Styles.navItem}>
                  <a
                    href="#topPlan"
                    onClick={e => handleClick(e, "topPlan")}
                    className={Styles.navLink}
                  >
                    {dictionary[language].plan}
                  </a>
                </li>
                <li className={Styles.navItem}>
                  <a
                    href="#topService"
                    onClick={e => handleClick(e, "topService")}
                    className={Styles.navLink}
                  >
                    {dictionary[language].service}
                  </a>
                </li>
                <li className={Styles.navItem}>
                  <a
                    href="#topResult"
                    onClick={e => handleClick(e, "topResult")}
                    className={Styles.navLink}
                  >
                    {dictionary[language].result}
                  </a>
                </li>
                <li className={Styles.navItem}>
                  <a
                    href="#koreaContact"
                    onClick={e => handleClick(e, "koreaContact")}
                    className={Styles.navLink}
                  >
                    {dictionary[language].contact}
                  </a>
                </li>
              </ul>
            </nav>
            <div className={Styles.headerSelectLanguageWrapper}>
              <div className={Styles.headerSelectLanguageContainer}>
                <label htmlFor="selectLanguage" />
                <select
                  className={Styles.headerSelectLanguage}
                  id="selectLanguage"
                  value={language} // 現在の言語でselectの値を制御
                  onChange={handleLanguageChange} // 言語が変更されたときに実行されるハンドラ
                  aria-label="Choose Language"
                >
                  <option
                    className={Styles.headerSelectLanguageOption}
                    value="en"
                  >
                    EN
                  </option>
                  <option
                    className={Styles.headerSelectLanguageOption}
                    value="ko"
                  >
                    KR
                  </option>
                </select>
              </div>
            </div>
            <button
              className={`${Styles.headerMenu} ${showNav ? Styles.isShow : ""}`}
              onClick={toggleMenu}
              type="button"
              aria-controls="nav"
              aria-expanded={showNav ? "true" : "false"}
              aria-label={showNav ? "close menu" : "open menu"}
            ></button>
          </div>
        </div>
      </header>
    </div>
  )
}
