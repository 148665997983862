import React from "react"
import Styles from "./index.module.scss"

//フッター
// import facebook from "../images/facebook_icon.svg"
// import twitter from "../images/twitter_icon.svg"
// import instagram from "../images/instagram_icon.svg"
import { StaticImage } from "gatsby-plugin-image"

export const Footer = () => {
  return (
    <footer className={Styles.footer} id="footer">
      <div className={Styles.footerInner}>
        <div className={Styles.footerText}>
          <ul className={Styles.footerList}>
            <li className={Styles.footerListItem}>
              <a
                href="https://www.tam-tam.co.jp/privacy/"
                target="_blank"
                rel="noreferrer"
              >
                プライバシーポリシー
              </a>
            </li>
            <li className={Styles.footerListItem}>
              <a
                href="https://www.tam-tam.co.jp/socialmediapolicy/"
                target="_blank"
                rel="noreferrer"
              >
                ソーシャルメディアガイドライン
              </a>
            </li>
          </ul>
          <p className={Styles.footerCopyright}>
            <small>© TAM inc. All Rights Reserved.</small>
          </p>
        </div>
        <div className={Styles.footerContainer}>
          <div className={Styles.footerPrivacyMark}>
            <StaticImage
              alt="プライバシーマーク"
              src="../../images/privacy_mark.png"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </footer>
  )
}
