/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import CookieConsent, { Cookies } from "react-cookie-consent"
import { useLocation } from "@reach/router"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"

import Styles from "./index.module.scss"

import { Footer } from "../footer"
import { Header } from "../header"

type Props = {
  children: React.ReactNode
}

export const Layout: React.VFC<Props> = ({ children }) => {
  const location = useLocation()
  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer />
      <CookieConsent
        disableStyles={true}
        location="bottom"
        buttonText="承諾する"
        enableDeclineButton
        declineButtonText="拒否する"
        cookieName="gatsby-gdpr-google-analytics"
        buttonWrapperClasses={Styles.cookieCustomButtonWrapper}
        buttonClasses={Styles.cookieCustomButton}
        declineButtonClasses={Styles.cookieCustomDeclineButton}
        containerClasses={Styles.cookieCustomContainer}
        contentClasses={Styles.cookieCustomContent}
        onAccept={() => {
          Cookies.set("gatsby-gdpr-google-tagmanager", true)
          initializeAndTrack(location)
        }}
      >
        当サイトは、ウェブサイトにおけるお客様の利用状況を把握するために
        Cookie（クッキー）を使用しています。「承諾する」をクリックすると、当サイトでの
        Cookie（クッキー）の使用に同意することになります。
      </CookieConsent>
    </>
  )
}
