import React from "react"
import Styles from "./index.module.scss"

//フッター
// import facebook from "../images/facebook_icon.svg"
// import twitter from "../images/twitter_icon.svg"
// import instagram from "../images/instagram_icon.svg"
import { StaticImage } from "gatsby-plugin-image"

const dictionary = {
  en: {
    tokyoOffice: "Tokyo office",
    osakaOffice: "Osaka office",
    tokyoAddress:
      "〒101-0052 2nd floor, Misaki Building, 3-28-9 Kanda Ogawacho, Chiyoda-ku, Tokyo",
    osakaAddress:
      "〒530-0053 3-7 Suehirocho, Kita Ward, Osaka City, Osaka Prefecture",
  },
  ko: {
    tokyoOffice: "도쿄 사무실",
    osakaOffice: "오사카 사무실",
    tokyoAddress:
      "〒101-0052 도쿄도 치요다구 칸다 오가와쵸 3-28-9 미사키빌딩 2층",
    osakaAddress: "〒530-0053 오사카부 오사카시 키타구 스에히로쵸 3-7",
  },
}
export const KoreaFooter: React.VFC<{ language: "en" | "ko" }> = ({
  language,
}) => {
  return (
    <footer className={Styles.koreaFooter} id="footer">
      <div className={Styles.koreaFooterInner}>
        <div className={Styles.koreaFooterText}>
          <dl className={Styles.koreaFooterList}>
            <dt className={Styles.koreaFooterListTitle}>
              {dictionary[language].tokyoOffice}
            </dt>
            <dd className={Styles.koreaFooterListData}>
              {dictionary[language].tokyoAddress}
            </dd>
            <dt className={Styles.koreaFooterListTitle}>
              {dictionary[language].osakaOffice}
            </dt>
            <dd className={Styles.koreaFooterListData}>
              {dictionary[language].osakaAddress}
            </dd>
          </dl>
          <p className={Styles.koreaFooterCopyright}>
            <small>Copyright &copy; TAM 2023</small>
          </p>
        </div>
      </div>
    </footer>
  )
}
