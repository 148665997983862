import React, { useState, useEffect, useCallback, MouseEvent } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Styles from "./index.module.scss"
import { useLocation } from "@reach/router"

export const Header: React.VFC = () => {
  const [showLink, setShowLink] = useState(false)
  const [showNav, setShowNav] = useState(false)
  const location = useLocation()

  useEffect(() => {
    const header = document.getElementById("headerWrap")

    // スクロール値の取得
    const getScroll = () => {
      const currentScrollMount = Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
      )
      if (currentScrollMount > 10 && header) {
        header.style.backgroundColor = "#fff"
        header.style.height = "100%"
      } else {
        header ? (header.style.backgroundColor = "transparent") : null
      }
    }
    // returnで忘れずにスクロールイベントの削除
    window.addEventListener("scroll", getScroll)
    return () => window.removeEventListener("scroll", getScroll)
  }, [])

  // リンクの表示
  useEffect(() => {
    if (document.getElementById("work")) {
      setShowLink(true)
    }
  }, [])

  const anchorScroll = (href: string) => {
    const targetEl = document.getElementById(href)
    if (targetEl) {
      const currentPosition = window.pageYOffset
      const targetTop = targetEl.getBoundingClientRect().top
      const headerElement = document.getElementById("header")
      const headerHeight = headerElement ? headerElement.clientHeight : 0
      const targetPosition = currentPosition + targetTop - headerHeight
      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      })
    }
  }

  // 外部リンクのページ内スクロール
  useEffect(() => {
    if (location.hash) {
      // レンダリングでスクロールが上手くいかなったので0.5秒後スクロール開始
      setTimeout(() => {
        const href = location.hash.substring(1)
        anchorScroll(href)
      }, 500)
    }
  }, [])
  // ページ内スクロール
  const handleClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>, href: string) => {
      e.preventDefault()
      anchorScroll(href)
      setShowNav(false)
    },
    []
  )

  // ハンバーガーメニューの開閉
  const toggleNav = useCallback(() => {
    setShowNav(!showNav)
  }, [showNav])

  return (
    <div>
      <header className={Styles.header} id="header">
        <div id="headerWrap" className={Styles.headerWrap}>
          <div className={Styles.headerInner}>
            <div className={Styles.headerLogo}>
              <Link className={Styles.headerLogoCompany} to="/">
                <StaticImage
                  placeholder="none"
                  alt="TAM ロゴ"
                  src="../../images/tamec_new_logo.svg"
                />
              </Link>
            </div>
            <div
              className={`${Styles.headerMenu} ${showNav && Styles.isShow}`}
              onClick={toggleNav}
            ></div>
            <nav
              className={`${Styles.headerNav} ${showNav && Styles.isVisible}`}
            >
              {/* <Link to="/service" className={Styles.navLink}>
                サービス
              </Link> */}
              <a
                href="/information/brochure.pdf"
                target="_blank"
                rel="noreferrer"
                className={Styles.navLink}
              >
                ECサービス紹介
              </a>
              {showLink && (
                <a
                  href="#"
                  onClick={e => handleClick(e, "work")}
                  className={Styles.navLink}
                >
                  EC実績紹介
                </a>
              )}
              {/* <Link to="/blog" className={Styles.navLink}>
                お役立ち資料
              </Link> */}
              {/* <p className={Styles.navLinkInvalid}>お役立ち資料</p> */}
              {/* <Link to="/news" className={Styles.navLink}>
                お知らせ・イベント
              </Link> */}
              <Link to="/blog/shopify" className={Styles.navLink}>
                shopifyアプリ
              </Link>
              <a
                href="https://www.tam-tam.co.jp/about/"
                target="_blank"
                rel="noreferrer"
                className={Styles.navLink}
              >
                会社概要
              </a>
              {/* <a
                href="https://www.tam-tam.co.jp/recruit/"
                target="_blank"
                rel="noreferrer"
                className={Styles.navLink}
              >
                採用情報
              </a> */}
              <a
                href="#"
                onClick={e => handleClick(e, "contact")}
                className={Styles.navLink}
              >
                お問い合わせ
              </a>
            </nav>
          </div>
        </div>
      </header>
    </div>
  )
}
